import { isDeviceResOrHigher, useDeviceType } from "@hooks/useDeviceType";
export var useNumberOfSlidesToShow = function () {
    var deviceType = useDeviceType();
    switch (deviceType) {
        case "mobile":
            return 2;
        case "tablet":
        case "desktop":
            return 3;
        default:
            return 4;
    }
};
export var useCarouselSettings = function () {
    var numberOfSlidesToShow = 1;
    var deviceType = useDeviceType();
    var carouselSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: numberOfSlidesToShow,
        slidesToScroll: 1,
        arrows: isDeviceResOrHigher(deviceType, "desktop"),
    };
    return carouselSettings;
};
